import storage from "~/assets/js/Storage";

export default {
  async request({ commit }) {
    let restaurantId = this.$localStore.get("activeRestaurantId");
    let menuId = this.$localStore.get("activeMenuId");

    try {
      const res = await this.$axios.get(
        `${this.$config.apiUrl}${this.$config.endpoints.analysis.get}?restaurant_id=${restaurantId}&menu_id=${menuId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$localStore.get("accessToken"),
          },
        }
      );

      const analysis = res.data;
      storage.putAnalysis(analysis);
      commit("init", analysis);
      return analysis;
    } catch (err) {
      console.log(err);
    }
  },
  requestPartial({ commit }) {
    let menuId = this.$localStore.get("activeMenuId");
    this.$axios
      .get(
        `${this.$config.apiUrl}${this.$config.endpoints.analysis.menu}?&menu_id=${menuId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$localStore.get("accessToken"),
          },
        }
      )
      .then((response) => {
        // save analysis in storage

        const manually_formatted_analisys_cuz_batto1300_cant_return_a_consistent_data_format =
          {
            menu_id: menuId,
            grafici: {
              efficienza_menu: [...response.data.menu_efficiency],
            },
            costo_cliente_unico: response.data.cost,
            ricavo_cliente_unico: response.data.price,
          };

        storage.putPartialAnalysis(
          manually_formatted_analisys_cuz_batto1300_cant_return_a_consistent_data_format
        );

        // init vuex
        commit(
          "init",
          manually_formatted_analisys_cuz_batto1300_cant_return_a_consistent_data_format
        );

        // redirect to analysis page
        this.$router.push("/ristoratori/analisi-menu/analisi");
      })
      .catch((err) => {
        console.log(err);
        // Todo: display error or something
      });
  },
};
